// Generated by @nuxtjs/svg-sprite
export const sprites = {
  "languages": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/languages.svg").then(r => r.default || r),
  "languagesdark": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/languagesdark.svg").then(r => r.default || r),
  "services": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/services.svg").then(r => r.default || r),
  "articles": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/articles.svg").then(r => r.default || r),
  "benefits": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/benefits.svg").then(r => r.default || r),
  "common": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/common.svg").then(r => r.default || r),
  "social": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/social.svg").then(r => r.default || r),
  "solution": () => import("/home/ubuntu/trezlabs-frontend/assets/sprite/gen/solution.svg").then(r => r.default || r)
}
export const spriteClass = "";

export const spriteClassPrefix = "";

export const defaultSprite = "icons";
